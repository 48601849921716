const Config = {
  datatableFeatures: {
    extraButtons: [
      // {
      //   buttonLabel: 'Upload Settlement',
      //   buttonRef: 'uploadSettlementBtn',
      //   variant: 'info',
      // },
    ],
    tableStatusFilterButton: [
      // {
      //   buttonLabel: 'Pending',
      //   buttonRef: 'pendingSettlementFilter',
      //   active: true,
      // },
      // {
      //   buttonLabel: 'Completed',
      //   buttonRef: 'completedSettlementFilter',
      //   active: false,
      // },
    ],
    buttons: {

    },
    datepicker: false,
    showDTDetails: true,
    perPageDropdown: true,
    searchField: false,
    filter: false,
    pagination: true,
    search: true,
  },
  searchOptsDropdown: [
    { value: 'invoice_no', text: 'Invoice #' },
  ],
  formData() {
    return {
      title: {
        edit: 'Edit Commission',
        view: 'View Commission',
        remove: 'Remove Commission',
        create: 'Create New Commission',
      },
      form: [],
    }
  },
  tableData() {
    return {
      tableAction: [
        {
          enable: true, param: 'id', btnLabel: 'View', action: 'view', btnIcon: 'SearchIcon', btnOnly: true, variant: 'primary', size: 'sm', iconSize: '12', pageRoute: 'administration-commissions-datatable-details',
        },
        {
          enable: false, param: 'id', btnLabel: 'Edit', action: 'edit', btnIcon: 'Edit2Icon', btnOnly: true, variant: 'info', size: 'sm', iconSize: '12',
          // pageRoute: 'administration-plans-form',
        },
        {
          enable: false, param: 'id', btnLabel: 'Remove', action: 'remove', btnIcon: 'Trash2Icon', btnOnly: true, variant: 'danger', size: 'sm', iconSize: '12',
        },
      ],
      tableHeader: [
        // {
        //   "customer_bill_id": 2440,
        //   "commission_setting_id": 5,
        //   "commission_amount": 0.1,
        //   "release_commission_date": null,
        //   "account_id": 77,
        //   "referral_fee_amount": 0.8,
        //   "created_at": "2024-07-23T15:24:01",
        //   "id": 7,
        //   "commission_payout_record_id": 12,
        //   "paid": true,
        //   "bill_invoice": "bp-1294619DD6-lx",
        //   "referrer_fee_amount": 0.7,
        //   "record_status": 1,
        //   "updated_at": "2024-07-24T08:00:01"
        // }
        {
          key: 'show_details', label: '', sortable: false, visible: true, type: 'showDetails', class: 'show-details col-width',
        },

        {
          key: 'commission_payout_record_id', label: 'Id', sortable: true, visible: false, type: 'string',
        },
        {
          key: 'account_id', label: 'Account Id', sortable: true, visible: true, type: 'string', showInDetails: true,
        },
        {
          key: 'customer_bill_id', label: 'Customer Bill ID', sortable: true, visible: false, type: 'string',
        },
        {
          key: 'bill_invoice', label: 'Invoice #', sortable: true, visible: true, type: 'string',
        },
        {
          key: 'commission_amount', label: 'Commission', sortable: true, visible: true, type: 'monetary',
        },
        {
          key: 'referrer_fee_amount', label: 'Referrer Fee', sortable: true, visible: true, type: 'monetary', showInDetails: true,
        },
        {
          key: 'referral_fee_amount', label: 'Referral Fee', sortable: true, visible: true, type: 'monetary', showInDetails: true,
        },
        {
          key: 'paid', label: 'Paid', sortable: true, visible: true, type: 'boolFlag',
        },
        {
          key: 'record_status', label: 'Record Status', sortable: true, visible: false, type: 'string', showInDetails: true,
        },
        // {
        //   key: 'response_data', label: 'Response Data', sortable: true, visible: false, type: 'string', showInDetails: true,
        // },

        {
          key: 'release_commission_date', label: 'Released At', sortable: true, visible: false, class: 'text-right', type: 'date',
        },
        {
          key: 'updated_at', label: 'Updated At', sortable: true, visible: true, class: 'text-right', type: 'date',
        },
        {
          key: 'actions',
          label: 'Action',
          class: 'text-right',
          visible: true,
        },
      ],
    }
  },
}

export default Config
