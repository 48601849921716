<template>
  <div>
    <b-card>
      <template #header>
        <div class="d-flex justify-content-between">
          <h4 class="mr-1">
            Commission Detail
          </h4>
          <h4>
            <b-badge
              size="lg"
              :variant="commissionDetail.status === 'COMPLETED'? 'success': commissionDetail.status === 'PENDING'? 'warning': 'secondary'"
            >
              {{ commissionDetail.status }}
            </b-badge>
          </h4>
        </div>
      </template>
      <b-row>
        <!--        <b-col cols="4">-->
        <!--          <b-form-group>-->
        <!--            <label class="mb-0">Merchant Name</label>-->
        <!--            <b-form-input-->
        <!--              size="md"-->
        <!--              plaintext-->
        <!--              :value="commissionDetail.merchant_name"-->
        <!--            />-->
        <!--          </b-form-group>-->
        <!--        </b-col>-->
        <b-col cols="4">
          <b-form-group>
            <label class="mb-0">Merchant ID</label>
            <b-form-input
              size="md"
              plaintext
              :value="commissionDetail.merchant_id"
            />
          </b-form-group>

        </b-col>
        <b-col cols="4">
          <b-form-group>
            <label class="mb-0">Account ID</label>
            <b-form-input
              size="md"
              plaintext
              :value="commissionDetail.account_id"
            />
          </b-form-group>

        </b-col>
        <!--        <b-col cols="4">-->
        <!--          <b-form-group>-->
        <!--            <label class="mb-0">Commission UUID</label>-->
        <!--            <b-form-input-->
        <!--              size="md"-->
        <!--              plaintext-->
        <!--              :value="commissionDetail.settlement_uuid"-->
        <!--            />-->
        <!--          </b-form-group>-->
        <!--        </b-col>-->
        <!--        <b-col cols="4">-->
        <!--          <b-form-group>-->
        <!--            <label class="mb-0">Payment Provider</label>-->
        <!--            <b-form-input-->
        <!--              size="md"-->
        <!--              plaintext-->
        <!--              :value="commissionDetail.payment_provider"-->
        <!--            />-->
        <!--          </b-form-group>-->
        <!--        </b-col>-->
        <!--        <b-col cols="4">-->
        <!--          <b-form-group>-->
        <!--            <label class="mb-0">External Commission ID</label>-->
        <!--            <b-form-input-->
        <!--              size="md"-->
        <!--              plaintext-->
        <!--              :value="commissionDetail.external_settlement_id"-->
        <!--            />-->
        <!--          </b-form-group>-->
        <!--        </b-col>-->

        <b-col cols="4">
          <b-form-group>
            <label class="mb-0">Report ID</label>
            <b-form-input
              size="md"
              plaintext
              :value="commissionDetail.report_id"
            />
          </b-form-group>
        </b-col>

      </b-row>
      <b-row class="border-top pt-2">
        <!--        <b-col cols="4">-->
        <!--          <b-form-group>-->
        <!--            <label class="mb-0">Bank Account Number</label>-->
        <!--            <div>-->
        <!--              <b-form-input-->
        <!--                size="md"-->
        <!--                plaintext-->
        <!--                :value="commissionDetail.bank_account_number"-->
        <!--              />-->
        <!--            </div>-->
        <!--          </b-form-group>-->
        <!--        </b-col>-->

        <b-col cols="4">
          <b-form-group>
            <label class="mb-0">Payout Txn Number</label>

            <div>
              <b-form-input
                size="md"
                plaintext
                :value="commissionDetail.payout_transaction_no"
              />
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group>
            <label class="mb-0">Total Commission</label>

            <div><vue-numeric
              :value="commissionDetail.total_commission_amount"
              :precision="2"
              currency="MYR"
              read-only
            /></div>
          </b-form-group>
        </b-col>
        <!--        <b-col cols="4">-->
        <!--          <b-form-group>-->
        <!--            <label class="mb-0">Transaction Date</label>-->
        <!--            <b-form-input-->
        <!--              size="md"-->
        <!--              plaintext-->
        <!--              :value="commissionDetail.transaction_date"-->
        <!--            />-->
        <!--          </b-form-group>-->
        <!--        </b-col>-->
        <b-col cols="4">
          <b-form-group>
            <label class="mb-0">Released Date</label>
            <b-form-input
              size="md"
              plaintext
              :value="commissionDetail.release_date"
            />
          </b-form-group>
        </b-col>
        <!--        <b-col cols="4">-->
        <!--          <b-form-group>-->
        <!--            <label class="mb-0">Commission Generated Date</label>-->
        <!--            <b-form-input-->
        <!--              size="md"-->
        <!--              plaintext-->
        <!--              :value="commissionDetail.settlement_generated_date"-->
        <!--            />-->
        <!--          </b-form-group>-->
        <!--        </b-col>-->

      </b-row>
      <b-row class="border-top pt-2">
        <b-col cols="4">
          <b-form-group>
            <label class="mb-0">Update At</label>
            <b-form-input
              size="md"
              plaintext
              :value="commissionDetail.updated_at"
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group>
            <label class="mb-0">Created At</label>
            <b-form-input
              size="md"
              plaintext
              :value="commissionDetail.created_at"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>

    <lp-datatable
      ref="getCommissionRecordItemsRef"
      :extra-parameter="extraParam"
      title="Commission Records"
      store-module="commissions"
      store-data-endpoint="getCommissionRecordList"
      store-getter-endpoint="getCommissionRecordList"
      pagination-state-key="commissionRecordsList"
      :features="loadedConfig.datatableFeatures"
      :load-system-constant="true"
      default-search-field="invoice_no"
      default-sort-field="created_at"
      :search-options-dropdown="loadedConfig.searchOptsDropdown"
      :conf="loadedConfig"
    />
  </div>
</template>

<script>

import Config from '@/views/administration/commissions/commissionDetailformConfig'
import humaniseMixin from '@/common/humanise.mixin'
import LpDatatable from '@/leanpay-components/LPDataTable.vue'

export default {
  components: {
    LpDatatable,
  },
  mixins: [humaniseMixin],
  data() {
    return {
      currentRowData: {},
      cardTitle: 'Commission',
      dataGridEndpoint: [],
      module: 'commissions',
      confirmCheckbox: false,
      inputSwalData: '',
      selectedAdjustmentModalData: {},

      commissionDetail: {

      },

      extraParam: {
        settlement_uuid: this.$route.params.uuid,
      },
    }
  },
  computed: {
    loadedConfig() {
      if (Config) {
        return Config
      }
      return {}
    },
  },
  async mounted() {
    // this.isBusy = false
    const breadcrumbUpdatePayload = [
      { title: 'Commission Settlement', route: '/commissions' },
      { title: 'Commission Detail' },
    ]
    await this.$store.dispatch('breadcrumbs/setBreadcrumb', breadcrumbUpdatePayload)

    const payload = {
      _uuid: this.$route.params.uuid,
    }
    const res = await this.$store.dispatch('commissions/getCommissionDetail', payload)

    if (res.data.response_code === 2000) {
      this.commissionDetail = res.data.data
    }
    // this.initDataGrid()
  },
  methods: {

  },
}
</script>

<style lang="scss">
.leanx-swal {
  .swal2-input-label {
    justify-content: start;
  }

  .swal2-input {
    margin-bottom: 2rem
  }
}
</style>
